.product-teaser__content {
    font-size: calc(11rem / 16);
    line-height: calc(16/12);
    background: #fff;
    border-radius: calc(10rem / 16);
    padding: calc(70rem / 16) calc(15rem / 16) calc(10rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
        border-radius: calc(20rem / 16);
        padding: calc(100rem / 16) calc(30rem / 16) calc(18rem / 16) calc(30rem / 16);
    }
}
.product-teaser__subtitle {
    font-size: calc(8rem / 16);
    line-height: calc(14/10);
    letter-spacing: calc(.9rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(10rem / 16);
        letter-spacing: calc(1.5rem / 16);
    }
}
.product-teaser__title {
    font-size: calc(13rem / 16);
    line-height: calc(20/16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}

.product-teaser__img-container {
    background-image: url(/public/static/img/shapes/background-teaser.svg);
    background-repeat: no-repeat;
    background-position: 0 99%;
    background-size: 100% auto;
    margin-bottom: calc(-60rem / 16);
    position: relative;
    padding-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-90rem / 16);
    }
}

.product-grid__content .product-teaser__img-container {
    padding-bottom: 0;
}

.product-teaser__img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    filter: drop-shadow(0 calc(5rem / 16) calc(5rem / 16) rgba(32, 38, 40, 0.2));
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 1200px) {
        filter: drop-shadow(0 calc(10rem / 16) calc(10rem / 16) rgba(32, 38, 40, 0.2));
    }
}

.product-teaser:hover .product-teaser__img {
    transform: translateY(calc(-10rem / 16));
}

/* sizes */
.product-teaser--lg .product-teaser__img-container {
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);
}