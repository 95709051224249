.horizontal-teaser {
    border-radius: calc(10rem / 16);
}
.horizontal-teaser__content {
    padding: calc(40rem / 16) 0 0;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(40rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(20rem / 16) calc(70rem / 16);
    }
}
.horizontal-teaser__img {
    border-radius: calc(10rem / 16);
}

.horizontal-teaser + .horizontal-teaser {
    @media screen and (max-width: 767px) {
        margin-top: calc(24rem / 16);
    }
}

.horizontal-teaser__title {
    font-size: calc(24rem/16);
    line-height: calc(32/24);

    @media screen and (min-width: 1200px) {
        font-size: calc(32rem / 16);
    }
}

    /* with background */
.horizontal-teaser--bg .horizontal-teaser__img {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    @media screen and (min-width: 768px) {
        border-radius: calc(10rem / 16);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.horizontal-teaser--bg .horizontal-teaser__content {
    padding: calc(40rem / 16) calc(20rem / 16) calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(40rem / 16);
    }
    @media screen and (min-width: 1400px) {
        padding: calc(20rem / 16) calc(70rem / 16);
    }
}

/* media right */
.horizontal-teaser--media-right.horizontal-teaser--bg .horizontal-teaser__img {
    @media screen and (min-width: 768px) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}