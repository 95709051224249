.summary {
    line-height: calc(18/14);
}
.summary small {
    font-size: calc(10rem / 16);
}

.summary-item {
    grid-template-columns: 50% auto;
    column-gap: calc(10rem / 16);
    align-items: end;
}
.summary-item + .summary-item {
    margin-top: calc(12rem / 16);
}
.summary-item--total {
    padding: calc(14rem / 16) 0;
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    row-gap: calc(8rem / 16);
}
.summary__value {
    text-align: right;
}
.summary__value--total {
    font-size: calc(18rem / 16);
    line-height: 1;
}