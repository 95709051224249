.highlight-teaser {
    @media screen and (max-width: 767px) {
        padding-top: calc(84rem / 16);
    }
}
.highlight-teaser__badge {
    position: absolute;
    right: calc(-20rem / 16);
    top: calc(20rem / 16);
    width: calc(92rem / 16);
    height: calc(92rem / 16);
    border-radius: 50%;
    padding: calc(8rem / 16);
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) calc(-10rem/16) rgba(0,58,52,0.4);
    letter-spacing: calc(1.8rem / 16);
    font-size: calc(10rem / 16);
    line-height: calc(16/11);
    z-index: 1;

    @media screen and (min-width: 768px) {
        right: auto;
        left: calc(-26rem / 16);
        top: calc(-26rem / 16);
        width: calc(104rem / 16);
        height: calc(104rem / 16);
        font-size: calc(11rem / 16);
        letter-spacing: calc(2rem / 16);
    }
}
.highlight-teaser__content {
    padding: calc(10rem / 16) calc(30rem / 16) calc(30rem / 16);
    background: #fff;
    border-top-right-radius: calc(10rem / 16);
    border-bottom-right-radius: calc(10rem / 16);
    border-bottom-left-radius: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        border-bottom-left-radius: 0;
        border-top-right-radius: calc(10rem / 16);
        padding: calc(45rem / 16) calc(40rem / 16) calc(35rem / 16) 22%;
        margin-left: -10%;
        margin-bottom: calc(40rem / 16);
    }
}
.highlight-teaser__media {
    position: relative;
    padding-bottom: calc(42rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(20rem / 16);
        min-height: calc(330rem / 16);
        margin-right: -8%;
    }

    @media screen and (max-width: 767px) {
        background: #fff;
        border-top-left-radius: calc(10rem / 16);
        border-top-right-radius: calc(10rem / 16);
    }
}
.highlight-teaser__media-bg {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        border-top-right-radius: calc(10rem / 16);
        border-top-left-radius: calc(10rem / 16);
    }
}
.highlight-teaser__media-bg:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 105%;
    height: 104%;
    background: var(--color-secondary);
    transform: rotate(10deg);
    transform-origin: right top;
    border-bottom-right-radius: calc(80rem / 16);
}
.highlight-teaser__media-item {
    margin-top: calc(-84rem / 16);
    filter: drop-shadow(0px 10px 10px rgba(32, 38, 40, 0.3));
    transition: transform 150ms ease-in-out;
    width: auto;

    @media screen and (min-width: 768px) {
        margin-top: calc(-36rem / 16);
    }
}

.highlight-teaser:hover .highlight-teaser__media-item {
    transform: translateY(calc(-10rem / 16));
}