.wizard-nav {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.wizard-nav__item {
    width: 33.333%;
    flex-grow: 1;
    position: relative;
    justify-content: center;
    display: flex;
    max-width: calc(140rem / 16);
}
.wizard-nav__item:nth-child(2):after,
.wizard-nav__item:nth-child(2):before {
    content: '';
    position: absolute;
    top: calc(22rem / 16);
    right: 0;
    width: 150%;
    height: calc(1rem / 16);
    background: var(--color-primary-light);
}
.wizard-nav__item:before {
    right: auto;
    left: 0;
}
.wizard-nav__link {
    position: relative;
    color: var(--color-primary-light);
    font-size: calc(8rem / 16);
    letter-spacing: calc(1rem / 16);
    line-height: calc(15/10);
    padding: 0 calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(10rem / 16);
        letter-spacing: calc(1.5rem / 16);
        padding: 0 calc(20rem / 16);
    }
}
.wizard-nav__link-count {
    background: var(--color-light-grey);
    width: calc(45rem / 16);
    height: calc(45rem / 16);
    border-radius: calc(10rem / 16);
    border: calc(1rem / 16) solid var(--color-primary-light);
    color: var(--color-primary-light);
    font-size: calc(15rem / 16);
    position: relative;
    z-index: 1;
    margin-bottom: calc(12rem / 16);
}

.wizard-nav__link--finished .wizard-nav__link-count {
    background: #fff;
    border-color: #fff;
}
.wizard-nav__link--active .wizard-nav__link-count {
    color: #fff;
    background: linear-gradient(139.12deg, var(--color-primary-light) 0%, var(--color-primary-dark) 100%);
}