/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Background Color Helper */
.bg-primary {color: #fff;}
.bg-white {color: var(--color-primary);}
.bg-secondary-light {background: var(--color-secondary-light);}
.bg-light-grey {background: var(--color-light-grey);}

/* Text Color Helper */
.text-white { color: #ffffff; }
.text-grey { color: var(--color-grey); }
.text-color-default { color: var(--color-text-default); }
.text-primary-hover {transition: color 200ms ease-in-out;}
.text-primary-light, .text-primary-hover:hover { color: var(--color-primary-light); }
.text-gradient {
    background-image: linear-gradient(90deg,var(--color-primary-light) 8%, var(--color-primary-dark) 55%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}
hr[class*=text-] {opacity: 1;}

.cursor-pointer {
    cursor: pointer;
}

.price {
    font-size: calc(20rem / 16);
}

.arrow-link {
    font-size: calc(11rem / 16);
    letter-spacing: calc(2.5rem / 16);
}
.arrow-link__icon {
    font-size: calc(10rem / 16);
    margin-right: calc(15rem / 16);
    transition: transform 200ms ease-in-out;
}
.arrow-link:hover .arrow-link__icon {
    transform: translateX(calc(5rem / 16));
}

.object-fit-cover{
    object-fit: cover;
}

.border-radius-20 {
    border-radius: calc(20rem / 16);
}