:root {
    --main-navbar-height--xs: calc(70rem / 16);
}
@media screen and (max-width: 767px) {

    .main-navbar__search{
        position: fixed;
        bottom: calc(20rem/16);
        right: calc(20rem/16);
        z-index: 20;
    }
    .main-navbar__container {
        align-items: center;
        height: 100%;
    }
    .nav-toggle {
        flex-shrink: 0;
        background: linear-gradient(139.12deg, var(--color-primary-light) 0%, var(--color-primary-dark) 100%);
        color: #fff;
    }
    .nav-toggle:focus,
    .nav-toggle:hover {
        color: #fff;
    }
    .is-open.nav-toggle {
        background: var(--color-light-grey);
        color: var(--color-primary);
    }
    .nav-toggle__line {
        display: block;
        width: calc(16rem / 16);
        height: calc(3rem / 16);
        background: currentColor;
        transition: transform 250ms ease-in-out, opacity 200ms ease;
        transform-origin: left center;
        margin: 0 auto;
    }
    .nav-toggle__line + .nav-toggle__line {
        margin-top: calc(3rem / 16);
    }
    .is-open .nav-toggle__line {
        transform: rotate(45deg) translateX(0) translateY(-4px);
    }
    .is-open .nav-toggle__line:last-child {
        transform: rotate(-45deg) translateX(0) translateY(4px);
    }


    .main-navbar {
        height: var(--main-navbar-height--xs);
    }
    .main-navbar__logo,
    .main-navbar__right {
        transition: opacity 200ms ease-in-out;
    }
    .main-navbar.is-open .main-navbar__right,
    .main-navbar.is-open .main-navbar__logo {
        opacity: 0;
    }

    .mega-nav {
        display: none;
    }

    .mega-nav__item {
        display: block;
        width: 100%;
    }
    .mega-nav__title-link,
    .mega-nav__item-content {
        display: block;
        width: 100%;
        position: relative;
        color: var(--color-primary);
    }


    /* level 0 */
    .mega-nav--level-0 {
        background: var(--color-mint);
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 0;
        width: 100%;
        margin: 0;
        top: var(--main-navbar-height--xs);
        height: calc(100vh - var(--main-navbar-height--xs));
        max-height: calc(100% - var(--main-navbar-height--xs));
        visibility: hidden;
        transform: translateX(100%);
        transition: 120ms ease;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .mega-nav--level-0 .container {
        padding: 0;
    }
    .main-navbar.is-open .mega-nav--level-0 {
        z-index: 9;
        visibility: visible;
        transform: translateX(0);
    }
    .mega-nav__list--level-0 {
        background: #fff;
        padding: 0 calc(50rem / 16);
        position: relative;
        margin-bottom: calc(100rem / 16);
    }
    .mega-nav__list--level-0:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: calc(100rem / 16);
        border-bottom-left-radius: calc(40rem / 16);
        background: #fff;
        transform: rotate(-10deg) translateY(51px) skewX(16deg) translateX(4px);
        z-index: -1;
    }
    .mega-nav__item--level-0 + .mega-nav__item--level-0 {
        margin-top: calc(10rem / 16);
    }
    .mega-nav__item-content--level-0 {
        font-size: calc(16rem / 16);
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
        padding: calc(5rem / 16) 0;
        align-items: center;
    }
    .is-open > .mega-nav__item-content--level-0 {
        color: var(--color-primary);
    }

    /* Level 1 */
    .mega-nav--level-1 {
        padding-left: calc(20rem / 16);
        font-size: calc(14rem / 16);
    }
    .mega-nav__item.is-open > .mega-nav--level-1 {
        display: block;
    }
    .mega-nav__item-content--level-1 {
        padding: calc(6rem / 16) 0;
    }
    .mega-nav__title--level-1--overview {
        margin-left: calc(-20rem / 16);
    }
    .mega-nav__item--level-1.is-active .mega-nav__item-content--link{
        color: var(--color-primary-light);
        font-family: var(--font-default-bold);
    }
}

.mega-nav__collapse-icon {
    @media screen and (min-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 767px) {
        transition: transform 120ms ease-out;
        margin-left: 12px;
        font-size: 9px;
    }
}
.is-open > .mega-nav__item-content > .mega-nav__collapse-icon {
    transform: rotate(180deg);
}