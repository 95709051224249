.share-title {
    font-size: calc(11rem / 16);
    letter-spacing: calc(2rem / 16);
}
.share {
    border-radius: calc(16rem / 16);
    padding: calc(15rem / 16) 0;
    line-height: 1;
}
.share__item {
    position: relative;
    font-size: calc(15rem / 16);
    padding: calc(2rem / 16) calc(19rem / 16);
    color: var(--color-primary);
}
.share__item:hover,
.share__item:focus {
    color: var(--color-primary-light);
}
.share__item + .share__item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(1rem / 16);
    background: var(--color-light-grey);
}