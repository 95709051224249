.intro-block {
    position: relative;
}
.intro-block__text{
    font-size: calc(16rem/16);
    position: relative;
    padding-bottom: calc(30rem / 16);
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        padding-bottom: calc(45rem / 16);
    }
}
.intro-block__text:after{
    content: "";
    background-color: var(--color-primary);
    width: calc(100rem/16);
    height: calc(3rem/16);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}