.icon-text__icon {
    font-size: calc(45rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(45rem / 16);
    }
}
.icon-text__title {
    padding-top: calc(15rem / 16);
}
.icon-text__text {
    margin-top: calc(20rem / 16);
}