.history__area {
    position: relative;
}

.history__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    @media screen and(min-width: 768px) {
        flex-direction: row;
    }
}

.history__content {
    position: relative;
    background-color: #fff;
    border-radius: calc(30rem / 16);
    padding: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) calc(40rem / 16) calc(40rem / 16);
    }
}

.history__item--left .history__content {
    @media screen and (min-width: 768px) {
        margin-right: 2rem;
    }
}

.history__item--right .history__content {
    @media screen and (min-width: 768px) {
        margin-left: 2rem;
    }
}

.history__area::after {
    content: "";
    width: calc(3rem / 16);
    position: absolute;
    background-color: var(--color-mint);
    left: calc(50% - calc(1.5rem / 16));
    top: calc(25rem / 16);
    bottom: calc(25rem / 16);
    @media screen and (min-width: 768px) {
        top: calc(150rem / 16);
        bottom: calc(150rem / 16);
    }
}

.history__item--left .history__content:after,
.history__item--right .history__content:before {
    font-family: iconfont;
    content: var(--icon-nav-shape-small);
    position: absolute;
    color: #fff;
    font-size: calc(22rem / 16);
    @media screen and (min-width: 768px) {
        top: 50%;
    }
    @media screen and (max-width: 767px) {
        top: -1.75rem;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
    }
}

.history__item--left .history__content:after {
    @media screen and (min-width: 768px) {
        transform: translateY(-50%) rotate(270deg);
        right: calc(-57rem / 16);
    }
}

.history__item--right .history__content:before {
    @media screen and (min-width: 768px) {
        transform: translateY(-50%) rotate(90deg);
        left: calc(-57rem / 16);
    }
}

.history__item--left .history__year {
    @media screen and (min-width: 768px) {
        order: 2;
    }
}

.history__year {
    color: #fff;
    font-family: var(--font-default-bold);
    border-radius: 50%;
    z-index: 1;
    text-align: center;
    padding: calc(26rem / 16) 0;
    min-height: calc(75rem / 16);
    min-width: calc(75rem / 16);
    margin-bottom: 2.5rem;
    background: linear-gradient(139.12deg, var(--color-primary-light) 0%, var(--color-primary-dark) 100%);
    box-shadow: 0 20px 40px -10px rgba(0, 58, 52, 0.4);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) 0;
        min-height: calc(90rem / 16);
        min-width: calc(90rem / 16);
        margin-bottom: 0;
        font-size: calc(18rem / 16);
    }
}

.history__item--left .history__year {
    @media screen and (min-width: 768px) {
        margin-right: 1.5rem;
    }
    @media screen and (min-width: 993px) and (max-width: 1299px) {
        margin-right: clamp(1.5rem, 3.5vw, 3.125rem);
    }
    @media screen and (min-width: 1300px) {
        margin-right: 3.125rem;
    }
}

.history__item--right .history__year {
    @media screen and (min-width: 768px) {
        margin-left: 1.5rem;
    }
    @media screen and (min-width: 993px) and (max-width: 1299px) {
        margin-left: clamp(1.5rem, 3.5vw, 3.125rem);
    }
    @media screen and (min-width: 1300px) {
        margin-left: 3.125rem;
    }
}