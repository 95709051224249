.slide {
    margin-top: calc(60rem / 16);
    margin-bottom: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem / 16);
        margin-bottom: calc(80rem / 16);
    }
}

.slide-sm {
    margin-top: calc(25rem / 16);
    margin-bottom: calc(25rem / 16);
}

.slide-md {
    margin-top: calc(20rem / 16);
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
        margin-bottom: calc(60rem / 16);
    }
}

.slide[class*=bg-] {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: calc(40rem / 16);
    padding-bottom: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(80rem / 16);
        padding-bottom: calc(80rem / 16);
    }
}

.slide-md[class*=bg-] {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(60rem / 16);
        padding-bottom: calc(60rem / 16);
    }
}

.slide-sm[class*=bg-] {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: calc(25rem / 16);
    padding-bottom: calc(25rem / 16);
}