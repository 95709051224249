.audio-teaser {
    border-radius: calc(30rem / 16);
    padding: calc(25rem / 16) calc(20rem / 16);
    font-size: calc(12rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(48rem / 16) calc(50rem / 16) calc(40rem / 16);
        font-size: calc(14rem / 16);
    }
}
.audio-teaser__subtitle {
    font-size: calc(10rem / 16);
    line-height: calc(14/10);
    letter-spacing: calc(1.5rem / 16);
}
.audio-teaser__title {
    font-size: calc(16rem / 16);
    line-height: calc(25/18);
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.audio-teaser__audio {
    width: 100%;
    flex-shrink: 0;
}