.teaser-row {
    display: flex;
    flex-wrap: nowrap;
    @media screen and (min-width: 768px) {
        justify-content: center;
    }
}

.teaser-row__item {
    min-width: calc(160rem / 16);
    padding: 0 calc(5rem / 16);
    @media screen and (min-width: 768px) {
        width: 20%;
        padding: 0 calc(12rem / 16);
    }
}