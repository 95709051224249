/*
.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item ,
.vertical-gutter--1 > [class^="col"],
.vertical-gutter--1 > [class*=" col"]{
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item,
.vertical-gutter--2 > [class^="col"],
.vertical-gutter--2 > [class*=" col"] {
    margin-top: .5rem;
}
.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item,
.vertical-gutter--3 > [class^="col"],
.vertical-gutter--3 > [class*=" col"] {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item,
.vertical-gutter--4 > [class^="col"],
.vertical-gutter--4 > [class*=" col"] {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item,
.vertical-gutter--5 > [class^="col"],
.vertical-gutter--5 > [class*=" col"] {
    margin-top: 3rem;
}
*/

.vertical-gutter {
    margin-top: calc(-1 * var(--bs-gutter-y));
}
.vertical-gutter__item {
    margin-top: var(--bs-gutter-y);
}