.img-teaser {
    overflow: hidden;
    position: relative;
}
.img-teaser__img {
    transition: transform 200ms ease-in-out;
}
.img-teaser:hover .img-teaser__img {
    transform: scale(1.05);
}
.img-teaser:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
}
.img-teaser__content {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    color: #fff;
    padding: calc(16rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(35rem / 16);
    }
}
.img-teaser__subtitle {
    font-size: calc(8rem / 16);
    line-height: calc(16/11);
    letter-spacing: calc(1.5rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(11rem / 16);
    }
}
.img-teaser__title {
    font-size: calc(13rem / 16);
    margin-top: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(9rem / 16);
        font-size: calc(20rem / 16);
    }
}