@media screen and (max-width: 767px) {
    .anchor-nav {
        overflow-x: auto;
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: var(--color-primary);
        padding-left: 1rem;

        & .anchor-nav__list {
            white-space: nowrap;
        }

        & .anchor-nav__title {
            color: white;
        }

        & .anchor-nav__link {
            display: flex;
            align-items: center;
            column-gap: calc(4rem/16);
        }

        & .anchor-nav__item-icon {
            color: white;
            font-size: .5rem;
        }

        & .anchor-nav__item + .anchor-nav__item {
            padding-left: .5rem;
        }
    }
}