.asset-video{
    cursor: pointer;
}

.asset-video__play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
}

.asset-video__poster {
    z-index: 1;
}
.is-playing .asset-video__poster,
.is-playing .asset-video__play {
    display: none;
}

/* play video new */

.video-overlay__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 250ms ease;
    z-index: 4;
    cursor: pointer;
    color: var(--color-white);
}

.video-overlay__play-button .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-overlay {
    position: relative;
    cursor: pointer;
}

.video-overlay__overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.overlay-hide .video-overlay__overlay,
.overlay-hide .video-overlay__play-button,
.video-overlay:after {
    display: none;
    pointer-events: auto;
}

.video-overlay.overlay-hide{
    pointer-events: auto;
}