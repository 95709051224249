.sticky-bar {
    padding: calc(10rem / 16) 0;
    z-index: 3;

    @media screen and (min-width: 768px) {
        padding: calc(25rem / 16) 0;
    }
}
.sticky-bar.is-affix {
    box-shadow: 0 0 calc(15rem / 16) rgba(0, 0, 0, .05);
}