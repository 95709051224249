.download {
    border-radius: calc(20rem / 16);
    padding: calc(10rem / 16) calc(12rem / 16) calc(10rem / 16) calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(20rem / 16) calc(15rem / 16) calc(50rem / 16);
    }
}
.download__meta {
    font-size: calc(10rem / 16);
    letter-spacing: calc(1.5rem / 16);
    color: var(--color-primary);
    opacity: .5;
}