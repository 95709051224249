:root {
    --mega-nav-level-0-item-spacing: calc(10rem / 16);
}

.mega-nav__item-content--level-0 {
    @media (hover: none) {
        display: flex;
    }
    @media screen and (max-width: 767px) {
        display: flex;
    }
}

.mega-nav--level-0 {
    @media screen and (min-width: 768px) {
        height: 98px;
        display: flex;
        flex: auto;
        justify-content: center;
    }

    @media screen and (min-width: 1200px) {
        --mega-nav-level-0-item-spacing: calc(14rem / 16);
    }

    @media screen and (min-width: 1600px) {
        --mega-nav-level-0-item-spacing: calc(24rem / 16);
    }
}
.mega-nav__item.is-active > .mega-nav__item-content--level-0 {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    color: var(--color-primary-light);
}
.mega-nav__item-content--level-0 {
    @media screen and (min-width: 768px) {
        color: var(--color-primary);
        font-size: calc(12rem / 16);
        line-height: calc(20/14);
        padding: 1rem var(--mega-nav-level-0-item-spacing);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        position: relative;
        text-align: center;
        word-break: initial;
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(14rem / 16);
        letter-spacing: calc(.5rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .mega-nav__list--level-0 {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: calc(var(--mega-nav-level-0-item-spacing) * -1);
        margin-right: calc(var(--mega-nav-level-0-item-spacing) * -1);
    }

    .mega-nav__item--level-0 {
        display: flex;
        flex-direction: column;
        flex: auto;
        flex-grow: 0;
        position: static;
    }


    .mega-nav__item-content--level-0:hover,
    .mega-nav__item-content--level-0:focus,
    .mega-nav__item-content--level-0:active,
    .mega-nav__item.is-open > .mega-nav__item-content {
        color: var(--color-primary-light);
    }

    .mega-nav__item-content--level-0:after {
        font-family: iconfont;
        content: var(--icon-nav-shape);
        position: absolute;
        font-size: calc(24rem / 16);
        line-height: 1;
        color: #fff;
        z-index: 11;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) scaleY(0);
        transition-delay: 150ms;
        transition: transform 200ms ease-in-out;
        transform-origin: top center;
    }
    .mega-nav__item:hover > .mega-nav__item-content--level-0:after {
        transform: translateX(-50%) scaleY(1);
    }
}