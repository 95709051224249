:root {
    --color-primary: #004320;
    --color-secondary: var(--color-mint);
    --color-text-default: #202628;
    --color-text-muted: var(--color-dark-grey);

    --color-default: #202628;
    --color-white: #FFF;
    --color-light-grey: #F3F3F3;
    --color-grey: #DEDEDE;
    --color-dark-grey: #868686;
    --color-dark: #343A40; /* todo */

    --color-mint: #cce6d9;
    --color-success: #28A745;
    --color-info: #FF8022;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: #fff;
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #005027;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #E56100;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #008641;
    --color-secondary-light: #DDEFE5;
    --color-success-light: #1EE048;
    --color-info-light: #ff8c33;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "GorditaRegular", sans-serif;
    --font-default-weight: 400;
    --font-default-bold: "GorditaBold", sans-serif;
    --font-default-bold-weight: 400;
    //--font-default-medium: "Roboto-Medium", sans-serif;
    //--font-default-medium-weight: 400;

    --font-size-default: calc(14rem / 16);
}
