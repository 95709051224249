.mega-nav--level-1 {
    display: block;
}
@media screen and (min-width: 768px) {
    .mega-nav--level-1 {
        background-color: var(--color-mint);
        color: var(--color-primary);
        z-index: 10;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: calc(60rem / 16) 0;
        font-size: calc(15rem / 16);
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
        transition-delay: 150ms;

    }
    .mega-nav__list--level-1 {
        display: flex;
        flex-wrap: wrap;
    }
    .mega-nav__item:hover > .mega-nav--level-1,
    .mega-nav__item.is-open > .mega-nav--level-1 {
        opacity: 1;
        visibility: visible;
    }

    .mega-nav__title--level-1 {
        width: 100%;
        display: flex;
        flex: 0 0 100%;
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
    }
    .mega-nav__item-content--level-1 {
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
        padding: calc(7rem / 16) 0;
        display: block;
    }
    .mega-nav__item-content--level-1:hover {
        color: var(--color-primary-light);
    }
    .mega-nav__item--level-1 {
        display: flex;
        flex-direction: column;
        width: 33.3333%;
    }
    .mega-nav__item--level-1.is-active {
        color: var(--color-primary-light);
    }
}