.main-navbar__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--color-secondary);
    width: calc(486rem / 16);
    max-width: 100%;
    z-index: 2;
    border-bottom-left-radius: calc(10rem / 16);
    border-bottom-right-radius: calc(10rem / 16);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
    display: block;
}
.is-open > .main-navbar__dropdown {
    opacity: 1;
    visibility: visible;
}
.main-navbar__dropdown-content {
    padding: calc(40rem / 16);
}
.main-navbar__dropdown-content + .main-navbar__dropdown-content {
    border-top: calc(1rem / 16) solid rgba(255,255,255,.5);
}
.main-navbar__dropdown-scroll {
    max-height: calc(368rem / 16);
}

/* small dropdown */
.main-navbar__dropdown--sm {
    width: calc(352rem / 16);
    font-size: calc(15rem / 16);
}
.main-navbar__dropdown-content {
    padding: calc(50rem / 16) calc(35rem / 16) calc(28rem / 16);
}
.main-navbar__dropdown-item {
    color: var(--color-primary);
}
.main-navbar__dropdown-item:hover {
    color: var(--color-primary-light);
}
.main-navbar__dropdown-item + .main-navbar__dropdown-item {
    margin-top: calc(14rem / 16);
}
.main-navbar__dropdown-icon {
    font-size: calc(22rem / 16);
    width: calc(27rem / 16);
    vertical-align: -.2em;
    margin-right: calc(12rem / 16);
    text-align: center;
}
.main-navbar__dropdown-info {
    font-size: calc(10rem / 16);
    letter-spacing: calc(1.5rem / 16);
}