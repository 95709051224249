/* base stylings */
.form-control {
    border:0;
    font-size: calc(16rem / 16);
    padding: calc(25rem / 16) calc(20rem / 16) calc(8rem / 16);
    background: #fff;
    height: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}
//.form-control:focus {
//    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.35);
//}

.form--white .form-control {
    background: var(--color-light-grey);
}

.form-group {
    margin-bottom: calc(10rem / 16);
}

.form-control.form-control--light:disabled,
.form-control.form-control--light:disabled input{
    opacity: .75;
    background-color: #fff;
}

/* sizes */
.form-control.form-control-sm {
    height: calc(50rem / 16);
    padding: calc(25rem / 16) calc(20rem / 16) calc(8rem / 16);
}

/* addons */
.form-control-icon {
    position: absolute;
    right: calc(20rem / 16);
    top: 50%;
    transform: translateY(-50%);
    padding: 0 calc(10rem / 16);
    text-decoration: none;
    font-size: calc(20rem / 16);
    background: none;
    border: 0;
    color: var(--color-primary);
    display: flex;
}
.form-control-icon + .form-control {
    padding-left: calc(25rem / 16);
}

.legend {
    font-size: calc(14rem / 16);
    line-height: calc(16/14);
    margin-bottom: 0;
}

.registration-form__optional-field.show {
    display: block;
}
.registration-form__optional-field {
    display: none;
}

/* remove native number input styling */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}