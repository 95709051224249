.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(243, 243, 243, .25);
    z-index: 10;
    text-align: center;
    color: var(--color-primary-light);
}

.loading-overlay--centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading-overlay--btn {
    background: none;
    color: #fff;
}