.btn-number-spinner {
    font-size: calc(10rem/16);
    background-color: #fff;
    padding: calc(12rem/16);
    height: unset;
    @media screen and (min-width: 768px){
        padding: calc(15rem/16);
    }
}
.number-spinner__input {
    padding: calc(5rem/16) calc(15rem/16);
    height: unset;
    text-align: center;
    max-width: calc(75rem / 16);
    font-size: calc(10rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(13rem/16);
    }
}
.number-spinner__input::-webkit-outer-spin-button,
.number-spinner__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.number-spinner__input[type=number] {
    -moz-appearance: textfield;
}
.number-spinner--up{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.number-spinner--down{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.number-spinner--down:disabled,
.number-spinner--up:disabled {
    opacity: 1;
}

.number-spinner--down:disabled .icon,
.number-spinner--up:disabled .icon {
    opacity: .2;
}

.number-spinner--down .icon,
.number-spinner--up .icon {
    margin-top: calc(4rem/16);
}