@media screen and (max-width: 767px){
    .overlay {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: fixed;
        transform: translateX(-110%);
        transition: transform 200ms ease-in-out;
        z-index: 12;
        display: flex;
        flex-direction: column;
        background: var(--color-light-grey);
    }
    .overlay.is-open.no-transition {
        transition: none;
    }
    .overlay.is-open {
        transform: translateX(0);
    }
    .overlay__content {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: calc(40rem / 16) calc(30rem / 16);
        flex-grow: 1;
    }
    .overlay__bottom {
        padding: calc(40rem / 16) calc(30rem / 16);
    }
    .overlay__top {
        color: var(--color-primary);
        background: #fff;
        letter-spacing: calc(1.5rem / 16);
        text-transform: uppercase;
        padding: calc(10rem / 16) calc(60rem / 16);
        min-height: calc(70rem / 16);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .overlay__top-icon {
        font-size: calc(14rem / 16);
        margin-right: calc(5rem / 16);
    }
    .overlay__top-close {
        position: absolute;
        top: calc(10rem / 16);
        right: calc(10rem / 16);
    }
    .overlay__bottom-btn {
        text-transform: none;
        display: block;
        width: 100%;
    }
}