.custom-checkbox {
    position: relative;
    padding-left: calc(26rem / 16);
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    overflow: hidden;
    border: calc(1rem / 16) solid var(--color-primary);
    width: calc(18rem / 16);
    height: calc(18rem / 16);
    border-radius: calc(5rem / 16);
    top: calc(3rem / 16);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.5em;
    color: #fff;
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background: linear-gradient(139.12deg, var(--color-primary-light) 0%, var(--color-primary-dark) 100%);
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}
.custom-checkbox__text a {
    text-decoration: underline;
    color: var(--color-primary-light);
}


/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}