.cart-item {
    padding: calc(15rem / 16);
    border-radius: calc(15rem / 16);
    font-size: calc(13rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
        padding: calc(20rem / 16);
        border-radius: calc(20rem / 16);
    }
}
.cart-item + .cart-item {
    margin-top: calc(10rem / 16);
}
.cart-item__grid {
    gap: calc(12rem / 16);
    grid-template-columns: 50%;
    grid-template-areas: "header header";

    @media screen and (min-width: 768px) {
        padding-right: 0;
        gap: calc(14rem / 16);
        grid-template-areas: "header";
        grid-template-columns: 32% 8% 7% 10% 13% 8% 12%;
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: 29% 7% 7% 8% 23% 8% 10% auto;
    }
}
.cart-item--editable .car-item__grid {
    padding-right: calc(35rem / 16);

    @media screen and (min-width: 768px) {
        padding-right: 0;
    }
}
.cart-item__grid-head {
    gap: calc(10rem / 16);
    grid-template-columns: calc(64rem / 16) auto;
    grid-area: header;

    @media screen and (min-width: 768px) {
        gap: calc(14rem / 16);
    }
}
.cart-item__grid-edit {
    gap: calc(6rem / 16);
    grid-template-columns: 100%;

    @media screen and (min-width: 768px) {
        gap: calc(10rem / 16);
        grid-template-columns: auto auto;
    }

    @media screen and (max-width: 767px) {
        position: absolute;
        right: calc(10rem / 16);
        top: calc(10rem / 16);
    }
}
.cart-item__title {
    font-size: calc(13rem / 16);
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}
.cart-item__label {
    font-size: calc(10rem / 16);
    line-height: calc(12/10);
}
.cart-item__info {
    font-size: calc(9rem / 16);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(10rem / 16);
        letter-spacing: calc(1.5rem / 16);
        padding: 0 calc(10rem / 16);
    }
}
.cart-item__info-icon {
    font-size: calc(18rem / 16);
    margin-right: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
        margin-right: calc(8rem / 16);
    }
}

/* sizes */
.cart-item--sm {
    grid-template-columns: calc(80rem / 16) auto auto;
    font-size: calc(14rem / 16);
}
.cart-item__delete {
    font-size: calc(17rem / 16);
    padding: calc(4rem / 16);
}

.cart-item.cart-item--addon,
.cart-item.cart-item--md {
    padding: 0;
}
.cart-item--md .cart-item__grid {
    @media screen and (min-width: 768px) {
        grid-template-columns: 40% 12% 10% 1fr 18%;
    }
}
.cart-item--md .cart-item__grid-head {
    grid-template-columns: calc(54rem / 16) auto;
}

.cart-item--addon .cart-item__grid {
    grid-template-columns: 70% 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 18%;
    }
}


.cart-item-head {
    padding: 0 calc(20rem / 16);
    grid-template-columns: 32% 8% 7% 10% 10% 11%;
    column-gap: calc(14rem / 16);
    line-height: calc(20/14);
    margin-bottom: calc(14rem / 16);

    @media screen and (min-width: 1200px) {
        grid-template-columns: 29% 7% 7% 8% 11% 20%;
    }
}

.cart-item-head--md {
    padding: 0;
    grid-template-columns: 40% 12% 10% 1fr 18%!important;
}