.hero__subtitle {
    font-size: calc(11rem / 16);
    letter-spacing: calc(2rem / 16);
}
.hero__title {
    @media screen and (min-width: 768px) {
        margin-top: calc(6rem / 16);
        margin-bottom: calc(15rem / 16);
        line-height: 1.25;
    }
}
.hero__intro {
    font-size: calc(14rem / 16);
    line-height: calc(28/16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }

    @media screen and (min-width: 1400px) {
        max-width: 80%;
    }
}
.hero__intro a {
    text-decoration: underline;
    transition: color 0.1s ease-in;
    &:hover {
        color: var(--color-primary-light);
    }
}
.hero__img {
    border-bottom-right-radius: calc(30rem / 16);
}
.hero__grid {
    @media screen and (min-width: 768px){
        grid-template-columns: 50.4% auto;
    }
}
.hero__content {
    position: relative;
    padding: calc(16rem / 16) calc(20rem / 16) 0;

    @media screen and (min-width: 768px){
        padding: calc(80rem / 16) calc(30rem / 16) calc(50rem / 16) calc(40rem / 16);
    }

    @media screen and (min-width: 1200px){
        padding-left: calc(118rem / 16);
    }
}
.hero__content-back {
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px){
        position: absolute;
        left: calc(40rem / 16);
        top: calc(40rem / 16);
    }

    @media screen and (min-width: 1200px){
        left: calc(118rem / 16);
    }
}

.hero__badge {
    position: relative;
    max-width: calc(90rem / 16);
    margin-top: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        position: absolute;
        right: calc(20rem / 16);
        top: calc(20rem / 16);
        max-width: calc(130rem / 16);
    }
}
.hero__badge img {
    width: auto;
}

/* hero with background image */
.hero--bg {
    position: relative;
    z-index: 1;
    min-height: calc(380rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
    @media screen and (min-width: 1400px) {
        min-height: calc(466rem / 16);
        font-size: calc(16rem / 16);
    }
}

.hero--bg .hero__content {
    padding: 0;
    padding-top: calc(30rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        padding-top: calc(20rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .hero--bg .hero__title,
    .hero--bg .hero__subtitle,
    .hero--bg .hero__text {
        background-image: linear-gradient(90deg,var(--color-primary-light) 8%, var(--color-primary-dark) 55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
.hero__bg {
    position: absolute;
    right: 0;
    width: 93%;
    top: 0;
    overflow: hidden;
    z-index: -1;
    max-width: calc(350rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(1160rem / 16);
        width: 54%;
        padding-left: calc(16rem / 16);
    }
}
.hero__bg:after {
    content: "";
    position: absolute;
    max-width: 234px;
    width: 34%;
    height: 85px;
    left: 58%;
    top: 82%;
    background: #008641;
    background: var(--color-primary-light);
    border-bottom-left-radius: 2.5rem;
    opacity: .08;
    transform: rotate(-30deg) skewx(20deg) translateY(-100%);
    z-index: -1;
}
.hero__bg-container {
    border-bottom-left-radius: calc(40rem / 16);
    overflow: hidden;
    width: 100%;
    transform: rotate(-10deg) skewX(17deg);
    transform-origin: top left;

    @media screen and (min-width: 768px) {
        transform: rotate(-10deg) skewX(17deg);
    }
}
.hero__bg-img {
    transform: rotate(10deg) skewX(-17deg);
    transform-origin: top left;
}

@media screen and (max-width: 767px) {
    .hero__text-line {
        padding: calc(2rem / 16) calc(8rem / 16);
        display: inline;
        border-radius: calc(8rem / 16);
        background: var(--color-light-grey);
        box-shadow: 0px 0px 0 1px var(--color-light-grey),0 2px 0 1px var(--color-light-grey),0 -1px 0 1px var(--color-light-grey);
        -webkit-box-decoration-break: clone;
        -o-box-decoration-break: clone;
        box-decoration-break: clone;
        padding-left: 0;
    }
    .hero__text-line--lg {
        padding: 10px 17px 10px 0;
        box-shadow: 0px 0px 0 0px var(--color-light-grey),0 -11px 0 0px var(--color-light-grey),0 -4px 0 0px var(--color-light-grey);
    }

    .hero__subtitle,
    .hero__title,
    .hero__text {
        color: var(--color-primary-light);
        //gradient not work on IOS with this background
        //background-image: linear-gradient(90deg,var(--color-primary-light) 8%, var(--color-primary-dark) 55%);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
        //position: relative;
        display: inherit;
    }
}

/* hero product slider */
.hero-half {
    padding-top: calc(8rem / 16);
    position: relative;
    z-index: 0;
}
.hero-half__slider:not(.hero-half__slider--full) {
    position: relative;
    padding: calc(20rem / 16) calc(40rem / 16) calc(125rem / 16) calc(40rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) 0 calc(140rem / 16);
    }
}
.hero-half__slider:not(.hero-half__slider--full):before {
    content: '';
    position: absolute;
    background: var(--color-secondary-light);
    border-bottom-right-radius: calc(90rem / 16);
    transform: rotate(10deg);
    left: calc(-50vw - 1256px/2);
    right: calc(-85rem/16);
    bottom: calc(94rem/16);
    top: calc(-50vw - 1256px/2);
    z-index: -1;

    @media screen and (min-width: 768px) {
        border-bottom-right-radius: calc(170rem / 16);
        right: calc(-210rem/16);
        bottom: 23%;
    }
}
.hero-half__slider:not(.hero-half__slider--full) .hero-half__slider-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-height: 100%;
    width: auto;
}
.hero-half__slider-container{
    transform: rotate(10deg);
    overflow: hidden;
    margin-top: -16%;
    margin-left: -20%;
    border-bottom-right-radius: calc(90rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: -23%;
        margin-left: -12%;
        border-bottom-right-radius: calc(170rem / 16);
    }
}
.hero-half__slider--full.hero-half__slider {
    transform: rotate(-10deg);
    width: 115%;
    transform-origin: bottom right;
}

.hero__info{
    font-size: calc(12rem/16);
}
.hero__info-item-title {
    font-size: calc(14rem / 16);
}
.hero__info-item + .hero__info-item{
    position: relative;
    padding-left: calc(10rem / 16);
    margin-left: calc(6rem / 16);
}
.hero__info-item + .hero__info-item:before{
    content: "";
    background-color: currentColor;
    width: calc(4rem/16);
    height: calc(4rem/16);
    border-radius: calc(1.5rem / 16);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}