.data-box {
    border-radius: calc(20rem / 16);
    padding: calc(20rem / 16) calc(24rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(35rem / 16) calc(40rem / 16) calc(30rem / 16);
    }
}
.data-box__edit {
    position: absolute;
    right: calc(20rem / 16);
    top: calc(20rem / 16);
}