.collective-order-form {
    overflow-x: hidden;
    margin-bottom: calc(30rem / 16);
    @media screen and (min-width: 768px) {
        margin-bottom: calc(80rem / 16);
    }
}

.collective-order-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto auto;
    gap: calc(10rem / 16) calc(10rem / 16);
    grid-template-areas:
    ". ."
    ". .";
    padding: 1rem 0;
    @media screen and (min-width: 768px) {
        grid-template-columns: 3.25fr 1.25fr .75fr .75fr;
        grid-template-rows: 1fr;
        gap: 0 calc(8rem / 16);
        grid-template-areas:
        ". . . .";
    }
}

.collective-order-item--head {
    display: none;
    @media screen and (min-width: 768px) {
        display: grid;
        padding: calc(10rem / 16) calc(30rem / 16);
    }
}

.collective-order__product {
    background-color: rgba(255, 255, 255, 0.22);
    border-radius: 1rem;
    padding: calc(12rem / 16);
    margin-top: calc(10rem / 16);
    font-size: calc(11rem / 16);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: calc(14rem / 16);
    grid-template-areas:
    "description description"
    "number-spinner number-spinner"
    ". .";
    @media screen and (min-width: 768px) {
        grid-template-columns: 2.925fr 1.575fr .75fr .75fr;
        grid-template-rows: 1fr;
        gap: 0 calc(8rem / 16);
        grid-template-areas:
        ". . . .";
        padding: calc(10rem / 16) calc(30rem / 16);
        font-size: calc(13rem / 16);
    }
}

.collective-order-item__description {
    font-size: calc(11rem / 16);
    margin-bottom: calc(3rem / 16);
    font-family: var(--font-default-bold);
}

#number-spinner-area {
    @media screen and (max-width: 767px) {
        grid-area: number-spinner;
        text-align: center;
    }
}

#description-area {
    @media screen and (max-width: 767px) {
        grid-area: description;
    }
}

.collective-order__product__image {
    padding: 0 calc(4rem / 16);
    @media screen and (min-width: 768px){
        padding: 0 calc(8rem / 16);
    }
}

.collective-order__checkbox {
    margin-bottom: calc(3rem / 16);
    padding-left: 1.25rem;
    @media screen and (min-width: 768px) {
        padding-left: 1.5rem;
    }
}

.collective-order__checkbox .custom-checkbox__box {
    height: 1.25rem;
    width: 1.25rem;
    @media screen and (min-width: 768px) {
        height: 1.5rem;
        width: 1.5rem;
    }
    border: calc(2rem / 16) solid var(--color-primary-light);
}
.collective-order__checkbox .custom-checkbox__input:focus+ .custom-checkbox__box {
    border: calc(2rem / 16) solid var(--color-primary-light);
}
.collective-order__checkbox .custom-checkbox__input:checked + .custom-checkbox__box,
.collective-order__checkbox .custom-checkbox__input:focus:checked + .custom-checkbox__box {
    border: none;
}

.collective-order-variant__item {
    margin-top: calc(10rem / 16);
    background-color: rgba(255, 255, 255, 0.22);
    border-radius: 1rem;
}

.collective-order-variant__button {
    padding: calc(10rem / 16) calc(54rem / 16) calc(10rem / 16) calc(10rem / 16);
    font-size: calc(10rem / 16);
    transition: background-color .2s ease-in;
    border-radius: 1rem;
    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16) calc(100rem / 16) calc(10rem / 16) calc(10rem / 16);
        font-size: calc(13rem / 16);
    }
}

.collective-order-variant__button.collapsed:hover {
    background-color: rgba(255,255,255,.22);
}

.collective-order-variant__img {
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(4rem / 16);
    }
}

.collective-order-item--thumbnail-fix {
    @media screen and (min-width: 768px) {
        width: calc(50rem / 16);
        height: calc(50rem / 16);
    }
}

.collective-order-variant__body {
    padding: calc(1rem / 16) calc(10rem / 16) calc(10rem / 16)  calc(10rem / 16);
}

.collective-order__product--variant .collective-order__checkbox {
    margin-right: calc(12rem / 16);
    @media screen and (min-width: 768px){
        margin-right: calc(20rem / 16);
    }
}

.collective-order__product--variant {
    padding: calc(10rem / 16);
    background-color: rgba(255, 255, 255, 0.22);
    @media screen and (min-width: 768px) {
        background-color: transparent;
        padding: calc(10rem / 16) calc(20rem / 16);
    }
}

.collective-order__selected-count {
    text-transform: uppercase;
    font-size: calc(9rem / 16);
    letter-spacing: calc(1.5rem / 16);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    color: var(--color-primary-light);
    align-self: center;
    @media screen and (min-width: 768px) {
        font-size: calc(10rem / 16);
        letter-spacing: calc(2rem / 16);
    }
}

.collective-order-item__description--variant {
    @media screen and (max-width: 767px) {
        font-family: var(--font-default-bold);
        font-size: calc(11rem / 16);
    }
}

.collective-order-variant__item__head{
    @media screen and (max-width: 767px) {
        margin-left: calc(38rem/16);
    }
}

.collective-order-variant__button .collective-order__selected-count {
    @media screen and (max-width: 767px) {
        font-size: calc(7.5rem/16);
        letter-spacing: calc(1.5rem/16);
    }
}