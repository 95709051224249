.quotation {
    position: relative;
    padding-top: calc(45rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(100rem / 16);
    }
}
.quotation:before {
    content: var(--icon-quotation-mark);
    font-family: iconfont;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: calc(30rem / 16);
    line-height: 1;
    background-image: linear-gradient(90deg,var(--color-primary-light) 8%, var(--color-primary-dark) 55%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (min-width: 768px) {
        font-size: calc(60rem / 16);
    }
}
.quotation__text {
    font-size: calc(18rem / 16);
    line-height: calc(48/35);

    @media screen and (min-width: 768px) {
        font-size: calc(35rem / 16);
    }
}
.quotation__author {
    font-size: calc(11rem / 16);
    line-height: calc(16/11);
    letter-spacing: calc(1rem / 16);
    margin-top: calc(24rem / 16);


    @media screen and (min-width: 768px) {
        letter-spacing: calc(2rem / 16);
        margin-top: calc(36rem / 16);
    }
}