.wysiwyg table,
.table {
    position: relative;
    background: var(--bs-table-bg);
    border-radius: calc(20rem / 16);
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
    }
}
.wysiwyg table {
    width: 100%;
    background: var(--color-secondary);
}
.wysiwyg table:before,
.table:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: calc(1rem / 16) solid #fff;
    border-radius: calc(20rem / 16);
    pointer-events: none;
}
.wysiwyg table th:first-child,
.table thead th:first-child {
    border-top-left-radius: calc(20rem / 16);
}
.wysiwyg table th:last-child,
.table thead th:last-child {
    border-top-right-radius: calc(20rem / 16);
}
.wysiwyg table thead th,
.table thead th {
    color: var(--color-primary);
    font-size: calc(14rem / 16);
    padding: calc(12rem / 16) calc(10rem / 16);
    border-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(24rem / 16) calc(10rem / 16);
    }
}
.wysiwyg table thead th:first-child,
.table thead th:first-child {
    @media screen and (min-width: 768px) {
        padding-left: calc(40rem / 16);
    }
}
.wysiwyg table thead th:last-child,
.table thead th:last-child {
    @media screen and (min-width: 768px) {
        padding-right: calc(40rem / 16);
    }
}
.wysiwyg table tr:last-child td:last-child,
.table tbody tr:last-child td:last-child {
    border-bottom-right-radius: calc(20rem / 16);
}
.wysiwyg table tr:last-child td:first-child,
.table tbody tr:last-child td:first-child {
    border-bottom-left-radius: calc(20rem / 16);
}
.wysiwyg tbody tr td,
.table tbody tr td {
    border: 0;
    box-shadow: none;
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(21rem / 16) calc(12rem / 16);
    }
}
.wysiwyg tbody tr td:first-child,
.table tbody tr td:first-child {
    @media screen and (min-width: 768px) {
        padding-left: calc(40rem / 16);
    }
}
.wysiwyg tbody tr td:last-child,
.table tbody tr td:last-child {
    @media screen and (min-width: 768px) {
        padding-right: calc(40rem / 16);
    }
}
.table tbody tr:nth-child(odd) td,
.wysiwyg tbody tr:nth-child(odd) td {
    background: #fff;
}
.table tbody tr:nth-child(even) td,
.wysiwyg tbody tr:nth-child(even) td {
    background: var(--color-secondary-light);
}
.wysiwyg table tr:first-child td:first-child,
.table tbody tr:first-child td:first-child {
    border-top-left-radius: calc(20rem / 16);
}
.wysiwyg table tr:first-child td:last-child,
.table tbody tr:first-child td:last-child {
    border-top-right-radius: calc(20rem / 16);
}