.lightbox-link {
    padding: calc(10rem / 16);
    display: flex;
    align-items: flex-end;
    color: #fff;
    font-size: calc(16rem / 16);
    text-shadow: 0 0 calc(8rem / 16) rgba(0,0,0,.5);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        padding: calc(20rem / 16);
    }
}
.lightbox-link:hover {
    color: #fff;
}