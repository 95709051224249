.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    padding-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(45rem / 16); /* items shadow */
        margin-bottom: calc(-45rem / 16);
    }
}
.scrolling-area--vertical {
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (min-width: 768px) {
        padding: 0;
        margin: 0;
        padding-right: calc(10rem / 16);
    }
}
.scrolling-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
}

.scrolling-area--xs::-webkit-scrollbar,
.scrolling-area::-webkit-scrollbar {
    -webkit-appearance: none;
    width: calc(2rem / 16);
    height: calc(2rem / 16);
    background: var(--color-light-grey);
    transition: all 120ms ease;
}

.scrolling-area--xs::-webkit-scrollbar-thumb,
.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-mint);
    transition: all 120ms ease;
}
.scrolling-area__item {
    scroll-snap-align: start;
}
.scrolling-area__item:first-child {
    scroll-snap-align: center;
}
@media screen and (max-width: 767px) {
    .scrolling-area--xs {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        padding-top: calc(16rem / 16);
        padding-bottom: calc(16rem / 16);
        margin-top: calc(-16rem/16);
        margin-bottom: calc(-16rem/16);
    }

    .container .scrolling-area--fill-xs {
        margin-left: calc(-20rem / 16);
        margin-right: calc(-20rem / 16);
        padding-left: calc(18rem / 16);
        padding-right: calc(18rem / 16);
    }
}