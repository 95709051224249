.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(24rem / 16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary-light);
    text-decoration: underline;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary-light);
    text-decoration: underline;
}
.wysiwyg ul li + li {
    margin-top: calc(5rem / 16);
}

/* wysiwyg table style in table.scss */
.wysiwyg {
    @media screen and (max-width: 767px) {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
    }
}