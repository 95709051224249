.order-list__header__title {
    color: var(--color-primary);
    @media screen and (max-width: 767px){
        font-size: calc(9.5rem / 16);
    }
}

.order-list__summary__item{
    display: flex;
    justify-content: space-between;
}

.order-list__summary__item + .order-list__summary__item {
    @media screen and (min-width:768px) {
       margin-top: calc(8rem/16);
    }
}
.order-list__summary__item--price {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    @media screen and (min-width:768px) {
        margin-top: calc(8rem/16);
        padding-top: calc(8rem/16);
        border-top: calc(1rem/16) solid #fff;
        font-size: calc(16rem / 16);
    }
}
