.main-footer__main a:not(.btn):hover {
    text-decoration: underline;
}
.main-footer__box {
    margin-bottom: calc(-86rem / 16);
    padding: calc(40rem / 16);
    border-radius: calc(20rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        padding: calc(46rem / 16) calc(84rem / 16);
        box-shadow: 0 calc(20rem / 16) calc(40rem / 16) calc(-10rem/16) rgba(32,38,40,0.5);
    }
}
.main-footer__btn {
    padding: calc(9rem / 16) calc(15rem / 16);
    font-size: calc(13rem / 16);
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    text-transform: none;

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(16rem / 16);
    }
}
.main-footer__btn__small {
    font-size: calc(10rem / 16);

    @media screen and (min-width: 1400px) {
        font-size: calc(12rem / 16);
    }
}
.main-footer__btn-icon {
    font-size: calc(20rem / 16);
    margin-right: calc(10rem / 16);

    @media screen and (min-width: 1400px) {
        font-size: calc(24rem / 16);
    }
}

.main-footer__main {
    padding-top: calc(126rem / 16);
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.social-list__icon {
    font-size: calc(24rem / 16);
}
.main-footer__bottom {
    padding: calc(20rem / 16) 0;
    font-size: calc(11rem / 16);
    background-color: #F3F3F3;
}
.site-by-elements {
    width: calc(129rem / 16);
    height: auto;
}