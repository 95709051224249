
.modal-dialog {
    @media screen and (max-width: 767px) {
        margin-left: calc(15rem / 16);
        margin-right: calc(15rem / 16);
    }
}

.modal-content {
    background-color: var(--color-light-grey);
    border-radius: calc(20rem / 16);
    border: none;
    position: relative;
    padding: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(28rem / 16) calc(80rem / 16) calc(40rem / 16);
    }
}

.modal-lg .modal-content,
.modal-xl .modal-content {
    @media screen and (min-width: 768px) {
        padding: calc(24rem / 16) calc(106rem / 16) calc(69rem / 16) calc(106rem / 16);
    }
}

.modal .btn-close,
.modal .modal-close {
    background-color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    opacity: 1;
    background-image: none;
    position: absolute;
    top: calc(20rem / 16);
    right: calc(20rem / 16);
}

.modal .btn-close:before,
.modal .modal-close:before {
    content: var(--icon-close);
    font-family: iconfont;
    color: var(--color-primary);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-header,
.modal-footer,
.modal-body {
    border: none;
    padding: 0;
}

.modal-header {
    margin-bottom: calc(40rem / 16);
}

.modal-footer {
    margin-top: calc(30rem / 16);
}

.modal-title {
    margin-top: 2rem;
}
.modal__link:hover {
    text-decoration: underline;
}
