.mega-nav__item-content--toggle {
    @media screen and (min-width: 768px) {
        display: none;
    }
}
.mega-nav__item.has-children > .mega-nav__item-content--link {
    @media (hover: none) {
        display: none;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}
.mega-nav__item.has-no-children > .mega-nav__item-content--toggle {
    @media (hover: none) {
        display: none;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

