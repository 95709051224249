html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(13rem / 16);
    line-height: calc(22/13);
    word-break: break-word;

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
        line-height: calc(24/14);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong,
.font-default-bold {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

h1, .h1 {
    font-size: calc(30rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(46rem/16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(52rem/16);
    }
}
h2, .h2 {
    font-size: calc(24rem/16);
    line-height: calc(32/24);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
    }
}
h3, .h3 {
    font-size: calc(20rem/16);
    line-height: 1.25;
}
h4, .h4 {
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
h5, .h5 {
    font-size: calc(11rem/16);
    letter-spacing: calc(2rem / 16);
    text-transform: uppercase;
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
/*
h6, .h6 {
    font-size: 1em;
}*/

table{
    word-break: initial;
}