.accordion-item {
    border-radius: calc(20rem / 16);
}
.accordion-item + .accordion-item {
    margin-top: calc(15rem / 16);
}
.accordion-button {
    display: block;
    padding: calc(12rem / 16) calc(60rem / 16) calc(12rem / 16) calc(20rem / 16);
    border-radius: calc(20rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-size: calc(12rem / 16);
    letter-spacing: normal;
    min-height: calc(55rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        padding: calc(30rem / 16) calc(100rem / 16) calc(25rem / 16) calc(50rem / 16);
    }
}
.accordion-button--xs-small{
    @media screen and (max-width: 767px) {
        padding: calc(12rem / 16) calc(60rem / 16) calc(12rem / 16) calc(12rem / 16);
    }
}
.accordion-button:after {
    content: none;
}
.accordion-button:before {
    content: var(--icon-nav-shape);
    font-family: iconfont;
    position: absolute;
    top: 100%;
    left: 50%;
    color: #fff;
    line-height: .9;
    font-size: calc(17rem / 16);
    transform: scaleY(1) translateX(-50%);
    transform-origin: top center;
    transition: transform 200ms ease-in-out;
}
.collapsed.accordion-button:before {
    transform: scaleY(0) translateX(-50%);
}
.accordion-button__btn {
    position: absolute;
    right: calc(10rem / 16);
    top: 50%;
    transform: translateY(-50%);
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    padding: calc(5rem / 16);
    border-radius: calc(13rem / 16);
    letter-spacing: 0;

    @media screen and (min-width: 768px) {
        width: calc(50rem / 16);
        height: calc(50rem / 16);
        right: calc(20rem / 16);
    }
}
.accordion-button__btn--centered{
    top: 50%;
    transform: translateY(-50%);
}
.accordion-button__btn.accordion-button__btn--right {
    right: calc(8rem / 16);
    top: 50%;
    transform: translateY(-50%);
    @media screen and (min-width:768px){
        right: calc(10rem / 16);
    }
}
.collapsed .accordion-button__btn-icon:before {
    content: var(--icon-plus);
}
.accordion-body {
    background: var(--color-mint);
    border-radius: calc(20rem / 16);
    padding: calc(25rem / 16) calc(20rem / 16) calc(15rem / 16);

    @media screen and (min-width:768px){
        padding: calc(45rem / 16) calc(50rem / 16);
    }
}

.accordion-body a {
    text-decoration: underline;
    transition: color 0.1s ease-in;
    &:hover {
        color: var(--color-primary-light);
    }
}

.accordion-body--md {
    padding: calc(15rem / 16);

    @media screen and (min-width:768px){
        padding: calc(36rem / 16);
    }
}

.accordion-body--sm {
    padding: calc(24rem / 16) calc(8rem / 16) calc(8rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(32rem / 16) calc(20rem / 16) calc(10rem / 16);
    }
}

.accordion-item--sm .accordion-button{
    min-height: unset;
    @media screen and (min-width: 768px) {
        padding: calc(18rem / 16) calc(100rem / 16) calc(18rem / 16) calc(30rem / 16);
    }
}
.accordion-item--sm .accordion-button:before {
    content: none;
}
.accordion-item--sm .accordion-body{
    background: #fff;

    @media screen and (min-width: 768px) {
        padding: 0 calc(30rem / 16) calc(40rem / 16);
    }
}