.lang-item {
    grid-template-columns: calc(25rem / 16) 1fr;
    column-gap: calc(14rem / 16);
}
.lang-item + .lang-item {
    margin-top: calc(20rem / 16);
}
.lang-item__flag {
    width: calc(25rem / 16);
    height: calc(25rem / 16);
}
.lang-item__title {
    font-size: calc(16rem / 16);
    line-height: calc(23/16);
    margin-bottom: calc(5rem / 16);
}
.lang-item__link {
    color: var(--color-primary-light);
    border-radius: calc(10rem / 16);
    padding: calc(5rem / 16) 0;
}
.lang-item__link:not(:last-child) {
    margin-right: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(16rem / 16);
    }
}
.lang-item__link.is-active {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    background: var(--color-secondary);
    padding: calc(5rem / 16) calc(10rem / 16);
}