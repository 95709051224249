.main-navbar {
    background: #fff;
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) calc(-10rem/16) rgba(0,58,52,0.1);
    padding: 0 calc(5rem / 16);
    position: relative;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;

    @media screen and (min-width: 768px) {
        padding: 0 calc(10rem / 16);
    }

    @media screen and (min-height: 1400px) {
        padding-right: calc(30rem / 16);
    }
}
.is-affix.main-navbar {
    position: fixed;
}
.main-navbar__logo {
    height: auto;
    flex-grow: 0;
    max-width: calc(210rem / 16);
    padding-right: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(16rem / 16) 0;
        max-width: calc(160rem / 16);
    }

    @media screen and (min-width: 1400px) {
        max-width: calc(220rem / 16);
    }

    @media screen and (min-width: 1600px) {
        max-width: calc(260rem / 16);
    }
}

.main-navbar__right {
    list-style: none;
    padding: 0;
    flex-shrink: 0;
}
.main-navbar__right li:after {
    content: var(--icon-nav-shape-small);
    font-family: 'iconfont';
    position: absolute;
    top: 100%;
    z-index: 2;
    line-height: 1;
    color: #fff;
    transform: scaleY(0);
    transition: transform 200ms ease-in-out;
    transform-origin: top center;
}
.main-navbar__right li.is-open:after {
    transform: scaleY(1);
}