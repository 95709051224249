html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
    main {
        overflow-x: hidden;
    }
    .row:not([class*=" gx-"]):not([class*=" row-gutter--"]) {
        --bs-gutter-x: calc(10rem / 16)
    }

    .container.container--no-padding-xs {
        padding-left: 0;
        padding-right: 0;
    }
}
.container.container {
    max-width: calc(1316rem / 16); /* 1256px + container padding */
    width: 100%;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: calc(30rem / 16);
        padding-right: calc(30rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .container.container--move-left,
    .container.container--move-right {
        max-width: calc(1316px + (100vw - 1316px) / 2);
        padding-left: calc(12rem / 16);
        padding-right: 0;
        margin-left: auto;
        margin-right: 0;
    }
    .container.container--move-left  {
        margin-left: 0;
        margin-right: auto;
        padding-right: calc(12rem / 16);
        padding-left: 0;
    }
}
.container.container-narrow {
    max-width: calc(950rem / 16); /* 890px + container padding */
}
.container.container-xs {
    max-width: calc(888rem / 16); /* 828px + container padding */
}

.page-wrapper {
    background: url(/public/static/img/shapes/background.svg);
    background-repeat: no-repeat;
    background-position: calc(-110rem / 16) calc(380rem / 16);
    background-size: 120% auto;
    background-color: var(--color-light-grey);

    @media screen and (min-width: 768px) {
        background-position: calc(-60rem / 16) calc(460rem / 16);
        background-size: 50% auto;
    }
}
.page-wrapper--no-shape {
    background-image: none;
}
.page-wrapper--shape-top {
    background-position: calc(-110rem / 16) 0;

    @media screen and (min-width: 768px) {
        background-position: calc(-60rem / 16) 0;
    }
}