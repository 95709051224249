.collapsed .collapse-btn__more,
.collapse-btn__less {
    display: inline-block;
}
.collapse-btn__more,
.collapsed .collapse-btn__less {
    display: none;
}
.collapse-btn__icon {
    transform: rotate(-180deg);
    transition: transform 200ms ease-in-out;
}
.collapsed .collapse-btn__icon {
    transform: rotate(0);
}