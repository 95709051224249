.title-block {
    margin-bottom: calc(36rem / 16);
}
.title-block__title {
    margin-top: calc(10rem / 16);
    line-height: 1.4;
}
.title-block__greeting {
    font-size: calc(18rem/16);
    margin-bottom: calc(10rem / 16);
}


.title-block--lg {
    margin-bottom: calc(10rem / 16);
}
.title-block--lg .title-block__title {
    font-size: calc(31rem / 16);
    margin-top: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(43rem / 16);
    }
}
.title-block a {
    color: var(--color-primary-light);
    text-decoration: underline;
}
.title-block a:hover,
.title-block a:focus,
.title-block a:active {
    color: var(--color-primary-light);
    text-decoration: underline;
}