//@mixin btn-hover-mixin($name, $color, $colorDark) {
//    @keyframes #{$name} {
//        from {background: linear-gradient(139.12deg, $color 0%, $colorDark 100%)}
//        to {background: linear-gradient(139.12deg, $color 0%, $color 100%)}
//    }
//}

.btn {
    font-size: calc(11rem / 16);
    letter-spacing: calc(2.5rem / 16);
    padding: calc(12rem / 16) calc(19.5rem / 16) calc(12rem / 16) calc(22rem / 16); /* padding right is smaller for letter-spacing*/
    text-transform: uppercase;
}

/* colors */
.btn:not([class*=btn-outline-]) {
    border-color: transparent;
}
.btn-primary {
    background-size: 200% auto;
    transition: 0.35s;
    background-image: linear-gradient(139.12deg, var(--color-primary-light) 0%, var(--color-primary-dark) 50%, var(--color-primary-light) 100%);
    color: #fff;
}
.btn-primary:hover {
    background-position: 100%;
}
.btn-info {
    box-shadow: 0 calc(20rem / 16) calc(30rem / 16) calc(-10rem/16) rgba(217,93,0,0.4);
    background-size: 200% auto;
    transition: background-position 0.35s ease-in-out;
    background-image: linear-gradient(139.12deg, var(--color-info) 0%, var(--color-info-dark) 50%, var(--color-info-dark) 100%);
    color: var(--color-info-contrast);
}
.btn-info:hover,
.btn-info:focus {
    color: var(--color-info-contrast);
    background-position: 100%;
}
.btn-light-grey {
    background: var(--color-light-grey);
    color: var(--color-primary);
}
.btn-light-grey:hover {
    color: var(--color-primary-light);
}
.btn-white {
    background: #fff;
    color: var(--color-primary);
}
.btn-white:focus,
.btn-white:hover {
    color: var(--color-primary-light);
}
.btn-outline-white {
    border-color: #fff;
    color: #fff;
}
.btn-outline-white:hover {
    color: var(--color-primary);
    border-color: #fff;
    background: #fff;
}
.btn-outline-primary-light {
    color: var(--color-primary-light);
    border: calc(1rem/16) solid var(--color-primary-light);
}

.btn-link {
    text-decoration: none;
    font-size: calc(10rem / 16);
    letter-spacing: calc(1.6rem / 16);
}

/* sizes */
.btn-sm {
    font-size: calc(10rem / 16);
    letter-spacing: calc(1.5rem / 16);
    padding: calc(6rem / 16) calc(10rem / 16);
}
.btn-md {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    text-transform: none;
    letter-spacing: normal;
    padding: calc(15rem / 16) calc(20rem / 16) calc(14rem / 16);
    font-size: calc(14rem / 16);
}
.btn-lg {
    border-radius: calc(14rem / 16);
    padding: calc(18rem / 16) calc(31.5rem / 16) calc(16rem / 16) calc(34rem / 16); /* padding right is smaller for letter-spacing*/
}

.btn--has-shadow {
    box-shadow: 0 calc(20rem/16) calc(40rem/16) calc(-10rem/16) rgba(0,58,52,0.4);
}

.btn-input-group{
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* button icon */
.btn__icon {
    font-size: calc(19rem / 16);
    vertical-align: -0.25em;
    margin-right: calc(5rem / 16);
    top: calc(-1rem/16);
    position: relative;
}
.btn-sm .btn__icon {
    font-size: calc(9rem / 16);
    margin-right: calc(4rem / 16);
    top: 0;
    vertical-align: -.1em;
}

.btn__flag,
.btn .flag-svg {
    height: calc(20rem / 16);
    vertical-align: -0.26em;
}

/* cart button */
.btn-cart {
    position: relative;
}
.btn-cart__default {
    transition: opacity 200ms ease-in-out;
}
.is-finished .btn-cart__default,
.is-loading .btn-cart__default {
    opacity: 0;
}
.btn-cart__finished {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 200ms ease-in-out;
    font-size: calc(16rem / 16);
    @media screen and (min-width: 768px){
        font-size: calc(22rem / 16);
    }
}
.is-finished .btn-cart__finished {
    opacity: 1;
}