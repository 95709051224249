.sidenav {
    margin-bottom: calc(16rem/16)!important;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        margin: 0;
    }
}

.sidenav .nav-item {
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    width: 100%;

    @media screen and (max-width:767px) {
        width: auto;
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    &:hover .nav-link:not(.active) {
        color: var(--color-primary-light);
    }
}
.sidenav__icon  {
    font-size: calc(20rem/16);
    margin-right: calc(10rem/16);
}
.sidenav .nav-link{
    display: flex;
    align-items: center;
    color: var(--color-primary);
    padding: calc(8rem/16) calc(10rem/16);
    @media screen and (min-width: 768px){
        padding: calc(12rem/16) calc(20rem/16);
    }
}

.sidenav .nav-link.active {
    background-color: #fff;
    border-radius: calc(10rem/16);
    box-shadow: 0 calc(20rem/16) calc(40rem/16) calc(10rem/-16) rgba(0,58,52,0.1);
}

