.teaser__img-container {
    overflow: hidden;
}
.teaser__img {
    transition: transform 200ms ease-in-out;
}
.teaser:hover .teaser__img {
    transform: scale(1.05);
}
.teaser__badge {
    position: absolute;
    left: calc(20rem / 16);
    top: 0;
    transform: translateY(-50%);
    z-index: 1;
}
.teaser__badge-highlight {
    position: absolute;
    right: calc(-10rem / 16);
    top: calc(-10rem / 16);
    z-index: 1;
    width: calc(90rem / 16);
    height: calc(90rem / 16);
    border-radius: 50%;
    background: linear-gradient(139.12deg, var(--color-primary-light) 0%, var(--color-primary-dark) 100%);
    font-size: calc(13rem / 16);
    letter-spacing: calc(2.36rem / 16);
}
.teaser__category {
    font-size: calc(10rem / 16);
    line-height: calc(14/10);
    letter-spacing: calc(1.5rem / 16);
}
.teaser__title {
    font-size: calc(18rem / 16);
    line-height: calc(24/18);
}
.teaser__content {
    padding: calc(26rem / 16) calc(20rem / 16) 0;
}