@font-face {
    font-family: "iconfont";
    src: url('static/icons/font/iconfont.woff2') format('woff2'),
    url('static/icons/font/iconfont.woff') format('woff'),
    url('static/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-arrow-down-light:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-basket:before { content: "\EA06" }
.icon-book:before { content: "\EA07" }
.icon-checkmark:before { content: "\EA08" }
.icon-close:before { content: "\EA09" }
.icon-dashboard:before { content: "\EA0A" }
.icon-delete:before { content: "\EA0B" }
.icon-dermatologisch-getestet:before { content: "\EA0C" }
.icon-download:before { content: "\EA0D" }
.icon-elements:before { content: "\EA0E" }
.icon-expand:before { content: "\EA0F" }
.icon-facebook:before { content: "\EA10" }
.icon-fax:before { content: "\EA11" }
.icon-filter:before { content: "\EA12" }
.icon-gluten-free:before { content: "\EA13" }
.icon-hat:before { content: "\EA14" }
.icon-info:before { content: "\EA15" }
.icon-instagram:before { content: "\EA16" }
.icon-linkedin:before { content: "\EA17" }
.icon-mail:before { content: "\EA18" }
.icon-minus:before { content: "\EA19" }
.icon-nav-shape-small:before { content: "\EA1A" }
.icon-nav-shape:before { content: "\EA1B" }
.icon-note:before { content: "\EA1C" }
.icon-ohne-magnesiumserat:before { content: "\EA1D" }
.icon-order:before { content: "\EA1E" }
.icon-pen:before { content: "\EA1F" }
.icon-pharmacy:before { content: "\EA20" }
.icon-phone:before { content: "\EA21" }
.icon-pinterest:before { content: "\EA22" }
.icon-play:before { content: "\EA23" }
.icon-plus:before { content: "\EA24" }
.icon-print:before { content: "\EA25" }
.icon-production:before { content: "\EA26" }
.icon-quotation-mark:before { content: "\EA27" }
.icon-rohstoffe:before { content: "\EA28" }
.icon-search:before { content: "\EA29" }
.icon-seminar:before { content: "\EA2A" }
.icon-star:before { content: "\EA2B" }
.icon-tablets:before { content: "\EA2C" }
.icon-tag:before { content: "\EA2D" }
.icon-twitter:before { content: "\EA2E" }
.icon-user:before { content: "\EA2F" }
.icon-vegan:before { content: "\EA30" }
.icon-web:before { content: "\EA31" }
.icon-whatsapp:before { content: "\EA32" }
.icon-youtube:before { content: "\EA33" }


:root {
--icon-addthis: "\EA01";
    --icon-arrow-down-light: "\EA02";
    --icon-arrow-down: "\EA03";
    --icon-arrow-left: "\EA04";
    --icon-arrow-right: "\EA05";
    --icon-basket: "\EA06";
    --icon-book: "\EA07";
    --icon-checkmark: "\EA08";
    --icon-close: "\EA09";
    --icon-dashboard: "\EA0A";
    --icon-delete: "\EA0B";
    --icon-dermatologisch-getestet: "\EA0C";
    --icon-download: "\EA0D";
    --icon-elements: "\EA0E";
    --icon-expand: "\EA0F";
    --icon-facebook: "\EA10";
    --icon-fax: "\EA11";
    --icon-filter: "\EA12";
    --icon-gluten-free: "\EA13";
    --icon-hat: "\EA14";
    --icon-info: "\EA15";
    --icon-instagram: "\EA16";
    --icon-linkedin: "\EA17";
    --icon-mail: "\EA18";
    --icon-minus: "\EA19";
    --icon-nav-shape-small: "\EA1A";
    --icon-nav-shape: "\EA1B";
    --icon-note: "\EA1C";
    --icon-ohne-magnesiumserat: "\EA1D";
    --icon-order: "\EA1E";
    --icon-pen: "\EA1F";
    --icon-pharmacy: "\EA20";
    --icon-phone: "\EA21";
    --icon-pinterest: "\EA22";
    --icon-play: "\EA23";
    --icon-plus: "\EA24";
    --icon-print: "\EA25";
    --icon-production: "\EA26";
    --icon-quotation-mark: "\EA27";
    --icon-rohstoffe: "\EA28";
    --icon-search: "\EA29";
    --icon-seminar: "\EA2A";
    --icon-star: "\EA2B";
    --icon-tablets: "\EA2C";
    --icon-tag: "\EA2D";
    --icon-twitter: "\EA2E";
    --icon-user: "\EA2F";
    --icon-vegan: "\EA30";
    --icon-web: "\EA31";
    --icon-whatsapp: "\EA32";
    --icon-youtube: "\EA33";
    
}