.img-slide {
    min-height: calc(300rem / 16);
    position: relative;

    @media screen and (min-width: 1400px) {
        min-height: calc(550rem / 16);
    }
}
.img-slide:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(91.95deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
}
.img-slide__bg {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.img-slide__content {
    z-index: 1;
    text-shadow: 0 calc(3rem / 16) calc(12rem / 16) 0 rgba(0,0,0,0.5);
    padding: calc(40rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(80rem / 16) 0;
        font-size: 1rem;
        line-height: calc(28/16);
    }
}