.order-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto auto auto;
    gap:  calc(10rem/16) calc(10rem/16);
    grid-template-areas:
    "order-item__product order-item__product"
    ". ."
    ". ."
    ". .";
    border-bottom: calc(1rem/16) solid #fff;
    padding: 1rem 0;
    @media screen and (min-width:768px){
        grid-template-columns: 3fr .75fr .75fr 1fr .75fr 1fr;
        grid-template-rows: 1fr;
        gap: 0 calc(8rem/16);
        grid-template-areas:
        "order-item__product . . . . . .";
    }
}
.order-item__product {
    grid-area: order-item__product;
    display: flex;
}
.order-item__product__image{
    margin-right: calc(8rem/16);
}
.order-item--head {
    @media screen and (max-width:767px){
        display: none;
    }
}
.order-item__description {
    font-size:calc(10rem/16);
    @media screen and (min-width:768px){
       font-size:calc(12rem/16);
    }
}
.order-item__description--mobile{
    font-size:calc(10rem/16);
    @media screen and (min-width:768px){
        display: none;
    }
}
.order-item:not(.order-item--head) .order-item__item {
    @media screen and (min-width:768px){
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}