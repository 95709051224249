.scroll-slider {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.scroll-slider::-webkit-scrollbar {
    display: none;
}
.scroll-slider {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    position: relative;
}
.scroll-slider__item {
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: center;
}
.scroll-slider__arrow {
    position: absolute;
    z-index: 1;
    right: calc(20rem / 16);
    bottom: 0;
    transform: translateY(50%);
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    padding: calc(5rem / 16);
    border-radius: calc(14rem / 16);
    background: #fff;
    color: var(--color-primary);
    border: 0;
    font-size: calc(15rem / 16);
    box-shadow: 0 calc(20rem / 16) calc(40rem / 16) calc(-10rem/16) rgba(0,58,52,0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1400px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        border-radius: calc(18rem / 16);
    }
}
.scroll-slider__arrow.scroll-slider__arrow--prev {
    transform: translateY(50%) translateX(calc(-100% - calc(5rem / 16)));
}
.scroll-slider__arrow[disabled] {
    opacity: .7;
}
.scroll-slider__arrow:hover,
.scroll-slider__arrow:focus:not(:focus-visible) {
    outline: none;
    color: var(--color-primary-light);
}
.scroll-slider__arrow-icon {
    transform: rotate(-90deg);
}
.scroll-slider__arrow--prev .scroll-slider__arrow-icon {
    transform: rotate(90deg);
}

/* arrows bottom center */
.scroll-slider--arrows-center .scroll-slider__arrow {
    left: 50%;
    right: auto;
    transform: none;
}
.scroll-slider--arrows-center .scroll-slider__arrow--prev {
    left: 50%;
    transform: translateX(calc(-100% - calc(5rem / 16)));
}

/* small arrows */
.scroll-slider--arrows-sm .scroll-slider__arrow {
    height: calc(50rem / 16);
    width: calc(50rem / 16);
}

/* colors */
.scroll-slider--arrows-primary .scroll-slider__arrow {
    background: linear-gradient(139.12deg, var(--color-primary-light) 0%, var(--color-primary-dark) 100%);
    color: #fff;
}
.scroll-slider--arrows-primary .scroll-slider__arrow:focus {
    color: #fff;
}
.scroll-slider--arrows-primary .scroll-slider__arrow[disabled] {
    opacity: .5;
}

/* product teaser slider */
.product-teaser-slider {
    margin-top: calc(-20rem / 16);
}
.product-teaser-slider:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: -moz-linear-gradient(left,  rgba(243, 243, 243, 0.0) 0%, rgba(243, 243, 243, .8) 100%);
    background: -webkit-linear-gradient(left,  rgba(243, 243, 243, 0.0) 0%,rgba(243, 243, 243, .8) 100%);
    background: linear-gradient(to right,  rgba(243, 243, 243, 0.0) 0%,rgba(243, 243, 243, .8) 100%);
    width: calc(120rem / 16);
    pointer-events: none;

    @media screen and (min-width: 768px) {
        width: calc(380rem / 16);
    }
}
.product-teaser-slider .scroll-slider {
    padding: calc(20rem / 16) calc(30rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(60rem / 16) 0;
    }

    @media screen and (min-width: 1400px) {
        padding: calc(20rem / 16) calc(calc(100vw - calc(1256rem / 16) - calc(30rem / 16)) / 2) 0;
    }
}
.product-teaser-slider .scroll-slider__item {
    max-width: calc(184rem / 16);
    padding: 0 calc(12rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(256rem / 16);
    }
}

.hero-half__slider--full .scroll-slider__arrow {
    right: 20%;
    bottom: 22%;

    @media screen and (min-width: 768px) {
        right: 27%;
        bottom: 18%;
    }
}