.text-teaser {
    background-color: #fff;
    border-radius: calc(20rem/16);
    text-align: center;
    padding: calc(30rem/16) calc(20rem/16);
    @media screen and (min-width: 768px){
        padding: calc(50rem/16);
    }

}

.text-teaser__icon {
    font-size: calc(40rem/16);
    margin-bottom: calc(20rem/16);
}

.text-teaser__link {
    margin-top: calc(20rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}