.icon-btn {
    width: calc(50rem / 16);
    height: calc(50rem / 16);
    padding: calc(5rem / 16);
    border-radius: calc(14rem / 16);
    position: relative;
    letter-spacing: 0;

    @media screen and (min-width: 1400px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
        border-radius: calc(18rem / 16);
    }
}
.icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.icon-btn__icon.icon-play {
    font-size: calc(15rem / 16);
    margin-left: calc(1rem / 16);
}

svg.icon-btn__icon {
    height: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        height: calc(20rem / 16);
    }
}

/* sizes */
.icon-btn.icon-btn--sm {
    width: calc(45rem / 16);
    height: calc(45rem / 16);
    padding: calc(5rem / 16);
    border-radius: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(50rem / 16);
        height: calc(50rem / 16);
    }
}
.icon-btn--sm .icon-btn__icon {
    font-size: calc(16rem / 16);
}

.icon-btn__count {
    position: absolute;
    right: calc(-1rem / 16);
    bottom: calc(-1rem / 16);
    background: #fff;
    color: var(--color-info);
    padding: calc(5rem / 16) calc(7rem / 16);
    font-size: calc(10rem / 16);
    line-height: 1;
    border-radius: calc(6rem / 16);
    transition: opacity 200ms ease-in-out;
}
.icon-btn__count:empty {
    opacity: 0;
}

/* large icon */
.icon-btn .flag-svg {
    @media screen and (min-width: 768px) {
        height: calc(26rem / 16);
    }
}